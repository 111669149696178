import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import _ from 'lodash';
import ScrollToTop from 'react-scroll-to-top';
import { TiArrowUpThick } from 'react-icons/ti';
import { useAppDispatch } from 'src/redux/store';
import { NAV_DATA } from 'src/redux/navSlice';
const Header = dynamic(import('src/components/layouts/header'), { ssr: true });
const Footer = dynamic(import('src/components/layouts/footer'));

const Layout = ({ children, data, sessionSevId }: any) => {
  const router = useRouter();

  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      const find = data?.header?.filter((i: any) => i.nameEng === router?.query?.cid);
      if (!_.isEmpty(find)) {
        dispatch(NAV_DATA(find));
      }
    })();
  }, []);

  return (
    <>
      <Header data={data?.header} sessionSevId={sessionSevId} />
      {children}
      <ScrollToTop
        smooth
        color='#ffe600'
        component={
          <div className='scroll-to-top'>
            <TiArrowUpThick />
          </div>
        }
      />
      <Footer data={data?.footer} />
    </>
  );
};

export default Layout;
